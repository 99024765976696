h1 {
  margin: 1rem auto 4rem;
}

.product--image {
  width: 100%;
  height: 10em;
  object-fit: cover;
  border-radius: 0 6dvb 0 0;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: auto 1rem;
  text-align: center;
  font-family: arial;
  border-radius: 0 6cqh 0 65px;

}

.price {
  color: grey;
  font-size: 22px;
}

.card button {
  position: relative;
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: rgb(255, 80, 0);
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 60px;
  font-size: 18px;
  border-radius: 0 0 0 65px;
  bottom: -17px;
}

.card button:hover {
  opacity: 0.7;
}


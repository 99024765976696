/* cart products */
.cart-product {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px -6px #000;
  overflow: hidden;
  margin-bottom: 20px;
}

.cart-product:not(:first-child) {
  margin-bottom: 20px;
}

.cart-product-delivery {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
}

.cart-product-delivery .cart-product-deliveryItem {
  font-size: 14px;
  font-weight: 500;
}

.cart-product-delivery .cart-product-deliveryItem b {
  color: var(--buyrunOrange);
}

.cart-product .cart-product-innerWrapper{
  display: flex;
  padding: 20px;
}

.cart-product .cart-product-image {
  margin-right: 20px;
}

.cart-product .cart-product-meta {
  width: 100%;
}

.cart-product .cart-product-meta .cart-product-metaTitle {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
}

.cart-product .cart-product-metaActions {
  display: flex;
  align-items: center;
}

.cart-product .cart-product-meta .cart-product-metaQuantity {
  width: 120px;
}

.cart-product .cart-product-meta .cart-product-metaQuantity .incremental-button {
  padding: 5px 10px;
  line-height: 100%;
}

.cart-product .cart-product-meta .cart-product-metaQuantity .incremental-input {
  padding: 0;
  line-height: 100%;
}

.cart-product .cart-product-meta .cart-product-metaPrice {
  color: var(--buyrunBlue);
  font-weight: 500;
  font-size: 16px;
}
.categoryIcon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .categoryCard {
    border-radius: 45px 0 45px 0 !important;
  }
  
  .carousel .carouselItem {
    transition: transform 2s ease-in-out;
  }
/* profilbar.module.css */
.navContainer {
    display: flex;
    justify-content: center;
    min-width: max-content;
  }
  
  
  .navBar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navItem {
    margin: 0 10px;
    position: relative;
    transition: transform 0.3s ease; /* 2D dönüşüm için geçiş animasyonu */
  }
  
  .userName {
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    color: rgb(21,124,253);
  }
  
  .userName:hover {
    color: rgb(255, 80, 0);
  }
  
  .dropdownMenu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .navItem:hover .dropdownMenu {
    display: block;
  }
  
  .dropdownItem {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: rgb(21,124,253);
    transition: background-color 0.3s ease-in-out;
  }
  
  .dropdownItem:hover {
    background-color: #f1f1f1;
  }
  
  .navItem a {
    text-decoration: none; /* Alt çizgileri kaldır */
    color: rgb(21,124,253);
    transition: color 0.3s ease-in-out, transform 0.3s ease; /* Renk ve dönüşüm için geçiş animasyonu */
  }
  
  .navItem a {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease; /* Yumuşak geçişler için */
  }
  
  .navItem a:hover {
    color: rgb(255, 80, 0); /* Hover rengi */
    transform: rotate(5deg) scale(1.1); /* Hafif dönüş ve büyütme animasyonu */
  }
  
  .navItem a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 80, 0);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .navItem a:hover::before {
    transform: scaleX(1); /* Alt çizginin genişlemesi */
  }
  
  @media screen and (max-width: 768px) {
    .navBar {
      flex-direction: column;
    }
  
    .navItem {
      margin: 10px 0;
    }
  }
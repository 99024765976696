.leftSide {
  box-shadow: 10px 0 10px 0 #ddd;
}

.leftSide-menuTitle {
  position: relative;
  border-radius: 50px 0 0 50px;
  padding: 12px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  box-shadow: 5px 5px 10px 0 #ddd;
}

.leftSide-menuTitle.leftSide-sp {
  background-color: #ccc !important;
  overflow: hidden;
  cursor: pointer;
}

.leftSide-menuTitle.leftSide-sp span {
  position: relative;
  z-index: 2;
}

.leftSide-menuTitle.leftSide-sp::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: var(--bgColor);
  transition: ease 0.4s;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  opacity: 0;
}

.leftSide-menuTitle.leftSide-sp.active::after,
.leftSide-menuTitle.leftSide-sp:hover::after {
  width: 100%;
  opacity: 1;
}

.leftSide-listGroup .list-group-item {
  border-radius: 50px 0 0 50px!important;
  background-color: transparent;
  border: none;
  font-weight: 400;
  color: #000;
  display: inline-flex;
  align-items: center;
}

.leftSide-listGroup .list-group-item:hover,
.leftSide-listGroup .list-group-item.active {
  background-color: #feddcc;
}
.containerArea {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    height: 100% !important;
    width: 80% !important;
    margin: auto !important;
  }
  
  .tutorialArea2 {
    display: flexbox !important;
    height: 500px !important;
    width: 100%;
    margin-bottom: 20px; 
  }
  
  .tutorialArea2Part1 {
    background-image: url("../../../public/img/categoryimg-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(10px);
    border-radius: 0 0 95px 0 !important;
    max-width: 625px !important;
    margin-right: 15px; 
  }
  
  .tutorialArea2Part2 {
    background-image: url("../../../public/img/categoryimg-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 0 95px !important;
    max-width: 625px !important;
    margin-left: 15px; 
  }
  
  .tutorialArea2Part3 {
    background-image: url("../../../public/img/categoryimg-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 95px 0 0 !important;
    max-width: 625px !important;
    margin-right: 15px; 
  }
  
  .tutorialArea2Part4 {
    background-image: url("../../../public/img/categoryimg-4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 95px 0 0 0 !important;
    max-width: 625px !important;
    margin-left: 15px; 
  }
  
  @media (max-width: 991px) {
    .tutorialArea2 {
      width: 85% !important;
      max-width: none !important;
    }
  
    .tutorialArea2Part1, .tutorialArea2Part2 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  

  .tutorialtext {
   margin : -15px 0 -15px 0 !important;
  }
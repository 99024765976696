
.starRating {
  position: relative;
  /* max-width: max-content; */
}

.starRating-container {
  display: flex;
  /* justify-content: center; */
}

.starRating-wrapper {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 18px;
}

.starRating-front,
.starRating-back {
  position: absolute;
  overflow: hidden;
  color: #fccb0e;
}

.starRating-back {
  clip-path: inset(0px var(--rating) 0px 0px);
}

.starRating-text {
  color: #fccb0e;
}
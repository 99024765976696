:root {
    --buyrunRed: #fd1515;
    --buyrunBlue: rgba(21, 124, 253, 1);
    --buyrunOrange: rgba(255, 80, 0, 1);
    --buyrunYellow: #ffaf02;
    --buyrunGreen: #2ad352;
}


.icon-hover:hover {
    animation: spin 2s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.icon-horizontal-spin:hover {
    animation: horizontal-spin 1s linear 1;
}

@keyframes horizontal-spin {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}
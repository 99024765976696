.manufacturerList,
.conditionList {
  border: 1px solid aaa;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 #aaa;
}

.manufacturerList h5,
.conditionList h5{
  background-color: aliceblue;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 0 6px 0 #aaa;
}

.manufacturerListItems,
.conditionListItems {
  padding: 10px 20px;
}

.manufacturerListItems .manufacturerListItem,
.conditionListItems .conditionListItem {
}

.manufacturerListItems .manufacturerListItem label,
.conditionListItems .conditionListItem label {
  font-size: 14px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  width: 90%;
}
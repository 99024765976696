.breadcrumb .active {
    color: #007bff;
    font-weight: bold;
}

.btn-upload {
    height: 150px;
    width: 150px;
    background-color: transparent;
    color: #007bff;
    padding: 10px 20px;
    border-radius: 20px;
    border: dotted;
    box-shadow: 0 2px 4px rgba(0, 123, 255, .5);
    transition: background-color .3s, box-shadow .3s;
}

.btn-upload:hover,
.btn-upload:focus {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 123, 255, .5);

}

.btn-upload i {
    margin-right: 5px;
}

.media-preview-grid {
    display: grid;
    grid-template-columns: repeat(5, 150px);
    grid-gap: 10px;
    justify-content: start;
    margin-left: 10px;
}

.media-preview-item {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.media-preview-item img,
.media-preview-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/*  Manual Product Add page Styles */

/*  SideBar Custom Styles Starts Here*/
.active-link {
    pointer-events: none;
    color: grey !important;
    background-color: #feddcc !important;
    border-radius: 5px;
}

/*  SideBar Custom Styles Finish Here*/



.leftSide {
    box-shadow: 10px 0 10px 0 #ddd;
}

.leftSide-menuTitle {
    position: relative;
    border-radius: 50px 0 0 50px;
    padding: 12px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    box-shadow: 5px 5px 10px 0 #ddd;
}

.leftSide-menuTitle.leftSide-sp {
    background-color: #ccc !important;
    overflow: hidden;
    cursor: pointer;
}

.leftSide-menuTitle.leftSide-sp span {
    position: relative;
    z-index: 2;
}

.leftSide-menuTitle.leftSide-sp::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: var(--bgColor);
    transition: ease 0.4s;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    opacity: 0;
}

.leftSide-menuTitle.leftSide-sp.active::after,
.leftSide-menuTitle.leftSide-sp:hover::after {
    width: 100%;
    opacity: 1;
}

.list-group-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.list-group-item i {
    margin-right: 10px;
}

.ml-2 {
    margin-left: 8px;
}

.header-search-wrapper {
    position: relative;
}

.header-search-results {
    position: absolute;
    top: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    border: 1px solid #dddd;
    box-shadow: 0 10px 10px -10px #000;
    backdrop-filter: blur(5px);
}

.active-button {
    background-color: var(--bgColor);
}
/* src/pages/NotFound.css */

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

.not-found-content {
  max-width: 600px;
  padding: 20px;
}

.not-found-title {
  font-size: 10rem;
  margin-bottom: 20px;
  color: #343a40;
}

.not-found-text {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #6c757d;
}

.not-found-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #0056b3;
}

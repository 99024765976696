form:not(.was-validated) .form-control,
form:not(.was-validated) .form-select {
  border-color: var(--buyrunOrange);
}

form:not(.was-validated) .form-control:focus,
form:not(.was-validated) .form-select:focus {
  color: var(--buyrunOrange);
  border-color: var(--buyrunOrange);
  outline: 0;
  box-shadow: 0 0 0 .25rem #ffb491
}
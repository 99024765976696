.productItem {
  position: relative;
  border-radius: 50px 0 50px 0;
  overflow: hidden;
  box-shadow: 0 0 12px 0 #ccc;
}

.productItem-title {
  font-size: 16px;
  line-height: 1.4em;
  background-color: #eee;
}

.productItem-price-value {
  font-size: 22px;
  font-weight: 500;
}

.productItem-purchaseOptions {
}

.productItem-transportationOptions {
  font-size: 13px;
  line-height: 1.3em;
  color: var(--bs-secondary);
}

.productItem-purchaseOptionsButton {
  width: 100%;
  border: none;
  font-size: 14px;
  color: var(--bs-text-secondary);
  text-decoration: none;
}
.productItem-purchaseOptionsButton:hover {
  color: var(--bs-text-dark);
}

.productItem-gallery {
  position: relative;
}

.productItem-stars {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-image: linear-gradient(to top, black, transparent);
}

.productItem-stars .starRating-container {
  justify-content: center;
  padding: 5px;
}

.productItem-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addLine {
    display: flex ;
  }
  
  .addLine1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    width: 250px;
    height: 50px;
    background-color: #157cfd;
    color: aliceblue;
    font-size: 24px;
    font-weight: 600;
    z-index: 0;
  }
  
  .addLine1b {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    width: 250px;
    height: 50px;
    background-color: orangered;
    color: aliceblue;
    font-size: 24px;
    font-weight: 600;
    z-index: 0;
  }
  
  .addLine2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 220px;
    width: 300px;
    height: 50px;
    margin-top: 25px;
    background-color: aliceblue;
    font-size: 18px;
    font-weight: 600;
    color: #157cfd;
    border-radius: 35px 0 35px 0;
    z-index: 1;
  }
  
  .addLine2b {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 220px;
    width: 300px;
    height: 50px;
    margin-top: 25px;
    background-color: aliceblue;
    font-size: 18px;
    font-weight: 600;
    color:orangered;
    border-radius: 35px 0 35px 0;
    z-index: 1;
  }
.tutorialText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    background-color: #ff5000 !important;
    height: 100px;
}

@keyframes marqueeEffect {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes flashEffect {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.marquee {
    overflow: hidden;
    position: relative;
}

.marquee p {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;
    /* Başlangıçta metni dışarıda başlat */
    animation: marqueeEffect 20s linear infinite,
        /* Süre artırıldı */
        flashEffect 1s ease-in-out infinite;
}
.main {
    background-color: rgb(255, 80, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: sans-serif;
  }
  
  .card {
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 575px;
    max-width: 100%;
    padding: 50px;
    box-sizing: border-box;
    border-radius: 25px;
    z-index: 1;
    height: auto;
  }
  .cardInner {
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 25px;
    height: auto;
  }
  .languageOption {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  #form {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  
  .socialButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
  .link {
    color: #063970;
    text-decoration: none;
  }
  @media (max-width: 600px) {
    .card,
    .card-inner {
      width: 100%;
      padding: 10px;
    }
    .language-option {
      top: 10px;
      right: 10px;
    }
  }
  
  .customPartLeft1a,
  .customPartRight1a {
    display: flex;
    width: 9.7%;
    height: 83.5%;
    background-color: rgba(231, 156, 120, 0.8) !important;
    border-top: 15px solid white;
    border-bottom: 17px solid white;
    top: 9.9%;
    position: fixed;
    overflow: hidden;
  }
  
  .customPartLeft1a {
    border-radius: 0 4.5px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1.5);
  }
  
  .customPartRight1a{
    border-radius: 4.5px 0 0 0;
     box-shadow: 0 0 10px rgba(0, 0, 0, 1.5);
  }
  
  .customPartLeft1a {
   
    left: 0;
  }
  
  .customPartRight1a {
    right: 0;
  }
  
  .customPartLeft1b,
  .customPartRight1b {
    width: 26.45%;
    height: 83.5%;
    background-color: white;
    display: flex;
    position: fixed;
  }
  
  .customPartLeft1b {
    left: 9.4%;
    top: 9.85%;
    clip-path: url(#clip-path-left);
    
  }
  
  .customPartRight1b {
    right: 9.4%;
    top: 9.85%;
    clip-path: url(#clip-path-right);
  }
  
  .customPartLeft1bIn,
  .customPartRight1bIn {
    display: flex;
    width: 97%;
    height: 95.8%;
    background-color: rgba(231, 156, 120, 0.8);
    position: absolute;
    top: 2%;
     box-shadow: 0 0 10px rgba(0, 0, 0, 1.5);
  }
  
  .customPartLeft1bIn {
    left: 0;
    clip-path: url(#clip-path-left);
  }
  
  .customPartRight1bIn {
    right: 0;
    clip-path: url(#clip-path-right);
  }
  
  .customPartLeft1a,
  .customPartRight1a,
  .customPartLeft1b,
  .customPartRight1b,
  .customPartLeft1bIn,
  .customPartRight1bIn {
    transition: all 0.5s ease-out;
    overflow: hidden;
    z-index: 0;
  }
  
  @media (max-width: 768px) {
   .customPartLeft1a,
  .customPartRight1a,
  .customPartLeft1b,
  .customPartRight1b,
  .customPartLeft1bIn,
  .customPartRight1bIn {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }
  }
  
  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding-bottom: 10px;
  }
  
  .logoContainer .img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .input[type="text"],
  .input[type="password"],
  .input[type="email"] {
    width: 100%;
    padding: 5px;
    margin: 2% auto;
    font-size: 12px;
    border: 1.5px solid rgb(255, 80, 0);
    height: 35px;
  }
  
  form:not(.was-validated) .form-control {
    border-color: var(--buyrunOrange);
  }
  
  form:not(.was-validated) .form-control:focus {
    color: var(--buyrunOrange);
    border-color: var(--buyrunOrange);
    outline: 0;
    box-shadow: 0 0 0 .25rem #ffb491
  }
  
  .input[type="checkbox"]:checked {
    color: #ffa500;
  }
  
  .button{
    height: 40px;
    border-radius: 15px;
  }
  
  .btnRegister {
    background-color: rgb(255, 80, 0);
    border: #f5f5f5;
    margin-bottom: 15px;
    color: #f5f5f5;
    width: 100%;
  }

  .btnRegister:disabled {
   background-color: #cccccc;
    border: #f5f5f5;
    margin-bottom: 15px;
    color: #f5f5f5;
    width: 100%;
  }
  
  .socialButtonsCustom {
    border: #f5f5f5;
    background-color: #063970;
    color: #f5f5f5;
  }
  
  .button {
    position: relative;
    cursor: pointer;
  }
  
  .button:hover {
    animation: shake 0.5s 1;
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f70a6;
    
  }
  
  .checkboxLabel .link {
    color: #ffa500;
  }
  .checkboxInput {
    margin-right: 10px;
  }
  
  .checkboxText {
    font-size: 16px;
  }



.invalidFeedback p {
 color: #063970;
 font-weight: 500;
 font-size: 12px;
 margin-top: 5px;
}


@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


.invalidFeedbackActive p{
  display: block; 
  animation: fadeInSlideDown 0.5s ease forwards;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .checkboxLabel {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  margin-bottom: 10px; 
} */

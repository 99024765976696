.tutorialArea {
    background-color: inherit;
  }
  
  .tutorialAreaRow {
    background-color: inherit;
    height: 700px !important;
  }
  
  .tutorialPartLeft {
    background-image: url("../../../public/img/tutorialArea1Left.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 0 90px 0 90px;
  }
  
  .tutorialPartMiddle {
    background-image: url("../../../public/img/tutorialArea1Mid.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 0 90px 0 90px;
  }
  
  .tutorialPartRight {
    background-image: url("../../../public/img/tutorialArea1Right.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 0 90px 0 90px;
  }
  
  .tutorialText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    background-color: #ff5000 !important;
    border-radius: 0 90px 0 90px;
    height: 100px;
  }
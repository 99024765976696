.passwordStrongMeter {
  display: block;
  position: relative;
  background-color: #ccc;
  width: 100%;
  height: 10px;
  border-radius: 10px;
}
.passwordStrongMeter-bar {
  position: absolute;
  left: 0;
  top:0;
  height: 100%;
  border-radius: 10px;
  transition: ease all 0.5s;
}
.passwordStrongMeter-barHandle {
  content: '';
  position: absolute;
  left:0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  transition: ease all 0.5s;
}
.passwordStrongMeter-indicator{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 5px;
  margin-left: -5px;
  height: 100%;
  background-color: #000;
  z-index: 2; 
  border-radius: 10px;
}
.passwordStrongMeter-indicator-text{
  position: absolute;
  width: 100px;
  bottom: 100%;
  left: calc(-50px + 2.5px);
  text-align: center;
}
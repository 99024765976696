.variantTool {}

.variantTool.orange .variantTool-title,
.variantTool.orange .variantTool-info .fa-solid {
  color: var(--buyrunOrange);
}
.variantTool.blue .variantTool-title,
.variantTool.blue .variantTool-info .fa-solid {
  color: var(--buyrunBlue);
}

.variantTool.orange .variantTool-title,
.variantTool.orange .variantItem-title {
  color: var(--buyrunOrange);
}
.variantTool.blue .variantTool-title,
.variantTool.blue .variantItem-title {
  color: var(--buyrunBlue);
}

.variantTool-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed transparent;
  padding: 10px;
  border-radius: 6px;
}
.variantTool.orange .variantTool-wrapper,
.variantTool.orange .variantItem {
  border-color: var(--buyrunOrange);
}
.variantTool.blue .variantTool-wrapper,
.variantTool.blue .variantItem {
  border-color: var(--buyrunBlue);
}

.variantTool.orange .variantItem {
  background-color: #fff3f3;
}
.variantTool.blue .variantItem {
  background-color: #f3f8ff;
}

.variantTool.orange .variantTool-wrapper .lunchButton {
  color: var(--buyrunOrange);
}

.variantTool.blue .variantTool-wrapper .lunchButton {
  color: var(--buyrunBlue);
}

.variantTool-addButtonWrapper {
  position: absolute;
  right: 10px;
  top: 100%;
  border: inherit;
  border-top-color: transparent;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 6px;
}

.variantTool-addButton {
  width: 100%;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 8px 16px;
  line-height: 100%;
}
.variantTool.orange .variantTool-wrapper .variantTool-addButton {
  background-color: var(--buyrunOrange);
}
.variantTool.blue .variantTool-wrapper .variantTool-addButton {
  background-color: var(--buyrunBlue);
}

.w-fit {
  max-width: fit-content;
}

.bg-subtle {
  background-color: #eef5ff!important;
}

.br-6 {
  border-top-right-radius: 6px!important;
  border-bottom-right-radius: 6px!important;
}

.fs-14, .fs-14 > * {
  font-size: 11px;
  line-height: 1.4em;
}

.info-lines p {
  font-size: 14px;
  color: var(--bs-secondary);
}

.fa-money-check-dollar-pen,
.fa-arrow-progress {
  color: var(--buyrunOrange);
}

.ql-editor {
  min-height: 300px;
}

.quill.invalid {
  box-shadow: 0 0 0 1px var(--bs-danger);
}

.quill.valid {
  box-shadow: 0 0 0 1px var(--bs-success);
}

.fileBrowseButton {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--buyrunBlue);
  border-radius: 10px;
  font-weight: bold;
  color: var(--buyrunBlue);
  background-color: rgb(21 124 253 / 16%) !important;
}

.fileBrowseButton b {
  width: 80%;
  text-align: center;
  line-height: 1.3em;
}

.fileBrowseButton:hover {
  box-shadow: 0 0 20px -5px #555;
  cursor: pointer;
}

.imageThumbnail {
  position: relative;
  box-shadow: 0 0 10px 0 #ccc;
  border-radius: 10px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.imageThumbnail:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(to top, #000, transparent);
  z-index: 1;
}

.imageThumbnail img {
  width: auto;
  height: 200px;
}

.imageThumbnail-remove {
  position: absolute;
  right: 10px;
  top: 5px;
  color: rgba(255, 0, 0, 0.555);
}

.imageThumbnail-remove:hover {
  color: rgba(255, 0, 0, 1);
  cursor: pointer;
}

.imageThumbnail-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  font-size: 13px;
  line-height: 1.3em;
  width: 100%;
  word-wrap: break-word;
  color: #fff;
  z-index: 2;
}

.imagesWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 10px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px -5px #000;
}

.imagesWrapper > * {
  background-color: #ccc;
  border-radius: 5px;
}

.variantItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 15px;
}
.variantItem-title{
  line-height: 120%;
}

.variantItem-title b {
  color: #000;
}

.variantItem:not(:last-child){
  border-bottom: none;
}

.variantItem:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.variantItem:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.variantItem .variantItem-remove {
  min-width: fit-content;
}

.variantItem .variantItem-remove:hover {
  text-shadow: 0 0 6px;
  cursor: pointer;
}

.cart-section-addressDetails p {
  margin: 0;
}

.cart-section-title.cart-section-blue .cart-section-titleLeft{
  background-color: var(--buyrunBlue);
}
.cart-section-title.cart-section-orange .cart-section-titleLeft{
  background-color: var(--buyrunOrange);
}

.cart-section-title.cart-section-blue .cart-section-titleRight{
  color: var(--buyrunBlue);
}
.cart-section-title.cart-section-orange .cart-section-titleRight{
  color: var(--buyrunOrange);
}

.cart-section-title.cart-section-blue .cart-section-titleRight b{
  background-color: var(--buyrunBlue);
}
.cart-section-title.cart-section-orange .cart-section-titleRight b{
  background-color: var(--buyrunOrange);
}

.cart-section-title {
  position: relative;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
}

.cart-section-title:before {
  content: '';
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
}

.cart-section-title.cart-section-blue:before {
  background-color: var(--buyrunBlue);
}

.cart-section-title.cart-section-orange:before {
  background-color: var(--buyrunOrange);
}

.cart-section-titleWrapper {
  display: flex;
  box-shadow: 0 0 10px -5px #000;
  border-radius: 50px;
}

.cart-section-titleInner {
  padding: 4px;
}

.cart-section-title .cart-section-titleLeft{
  min-width: 120px;
  padding-right: 40px;
  color: #fff;
}

.cart-section-title .cart-section-titleRight{
  background-color: #fff;
  border-top-left-radius: 100px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-left: 40px;
  padding-right: 10px;
  margin-left: -32px;
  font-size: 16px;
  box-shadow: inset 4px -1px 2px 0 #ccc;
}

.cart-section-title .cart-section-titleRight b{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  margin-left: 10px;
  color: #fff;
}

.cart-section-titleSub {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 20px;
}

/* cart item */
.cart-section-item {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px -6px #000;
}

.cart-section-itemLeft-item h3 {
  display: block;
  font-size: 16px;
}

.cart-section-itemLeft-item p {
  margin: 0;
  font-size: 14px;
}

.cart-section-itemLeft-itemCard {
  display: inline-flex;
  align-items: center;
}

/* order */
#orderSummarySide {
  position: relative;
}

.order-summaryContainer {}
.fixed-element {
  position: fixed;
  top: 20px;
}

.order-summary {
  position: relative;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px -6px #000;
  overflow: hidden;
}

.order-summary-title {
  font-size: 18px;
  padding: 20px;
  background-color: var(--buyrunBlue);
  color: #fff;
  margin: 0;
}

.order-summary-inner {
  padding: 20px;
  background-color: #fff;;
}

.order-summary-innerItem{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order-summary-payNow:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #999;
}
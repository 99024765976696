
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff5000 !important;
  color: azure;
  height: 300px;
  transition: background-color 0.3s ease; 
}

.linksContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footerLink {
  margin: 0 10px;
  color: azure;
  text-decoration: none;
  position: relative;
}

.footerLink::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: azure;
  bottom: -3px;
  left: 0;
  transition: width 0.3s ease; 
}

.footerLink:hover::after {
  width: 100%; 
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.icon {
  position: relative;
  background: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 5px 0 5px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-radius:  50% 50% 0% 50%;
  /* border: #ff5000 solid 0.2px ; */
}

.tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  
}

.icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.icon:hover span,
.icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  
}

/* Sosyal medya ikon renkleri */
.facebook { --color: #1877f2; }
.twitter { --color: #1da1f2; }
.instagram { --color: #e4405f; }
.linkedin { --color: #1877f2; }
.youtube { --color: #cd201f; }

.icon:hover,
.icon:hover .tooltip,
.icon:hover .tooltip::before {
  background: var(--color);
  color: white;
  border: none;
}



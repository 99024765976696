.header-search-wrapper {
  position: relative;
}

.header-search-results {
  position: absolute;
  top: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  border: 1px solid #dddd;
  box-shadow: 0 10px 10px -10px #000;
  backdrop-filter: blur(5px);
}


.header-search-wrapper {
  position: relative;
}

.header-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.headerSearchItem {
  padding: 8px 16px;
  cursor: pointer;
}

.headerSearchItem:hover {
  background: #f9f9f9;
}
:root {
  --buyrunRed: #fd1515;
  --buyrunBlue: rgba(21, 124, 253, 1);
  --buyrunLightBlue: rgba(21, 124, 253, 0.2);
  --buyrunTableOddBlue: rgba(225, 242, 255, 1);
  --buyrunOrange: rgba(255, 80, 0, 1);
  --buyrunYellow: #ffaf02;
  --buyrunGreen: #2ad352;
  --buyrunGray: gray;
}

.buyrun-bgRed { background-color: var(--buyrunRed); }
.buyrun-bgBlue { background-color: var(--buyrunBlue); }
.buyrun-bgOrange { background-color: var(--buyrunOrange); }
.buyrun-bgYellow { background-color: var(--buyrunYellow); }
.buyrun-bgGreen { background-color: var(--buyrunGreen); }
.buyrun-bgGray { background-color: var(--buyrunGray); }

.buyrun-txtRed { color: var(--buyrunRed); }
.buyrun-txtBlue { color: var(--buyrunBlue); }
.buyrun-txtOrange { color: var(--buyrunOrange); }
.buyrun-txtYellow { color: var(--buyrunYellow); }
.buyrun-txtGreen { color: var(--buyrunGreen); }
.buyrun-txtGray { color: var(--buyrunGray); }

.buyrun-breadcrumb .breadcrumb-item {
  color: #000;
}

.buyrun-breadcrumb .breadcrumb-item.active {
  color: #157cfd;
  font-weight: 500;
}

.buyrun-form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-indent: 16px;
}

.buyrun-form-control,
.buyrun-form-select {
  box-shadow: inset 0 0 10px 0 #dcdcdc;
  border-radius: 50px;
  padding: 6px 16px;
}

.buyrun-form-select {
  padding-right: 32px;
}

input::placeholder,
select option[value="-1"] {
  color: #aaa !important;
}

.required {
  color: #f00;
  font-size: 24px;
  line-height: inherit;
}

.buyrun-fileLabel {
  display: flex;
  border: 2px dashed #ccc;
  border-radius: 20px;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.buyrun-selectedFilename {
  color: #157cfd;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 5px;
}

.buyrun-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.buyrun-icon.buyrun-icon-xs { min-width: 8px; min-height: 8px; }
.buyrun-icon.buyrun-icon-s { min-width: 16px; min-height: 16px; }
.buyrun-icon.buyrun-icon-m { min-width: 32px; min-height: 32px; }
.buyrun-icon.buyrun-icon-l { min-width: 64px; min-height: 64px; }
.buyrun-icon.buyrun-icon-xl { min-width: 128px; min-height: 128px; }
.buyrun-icon.buyrun-icon-xxl { min-width: 256px; min-height: 256px; }

.buyrun-icon.buyrun-icon-orders { background-image: url('../public/assets/img/ordersIcon.svg'); }
.buyrun-icon.buyrun-icon-refunds { background-image: url('../public/assets/img/refundsIcon.svg'); }
.buyrun-icon.buyrun-icon-reviews { background-image: url('../public/assets/img/reviewsIcon.svg'); }
.buyrun-icon.buyrun-icon-userMessages { background-image: url('../public/assets/img/userMessagesIcon.svg'); }
.buyrun-icon.buyrun-icon-requestArchive { background-image: url('../public/assets/img/requestArchiveIcon.svg'); }
.buyrun-icon.buyrun-icon-demandReports { background-image: url('../public/assets/img/demandReportsIcon.svg'); }
.buyrun-icon.buyrun-icon-newRequest { background-image: url('../public/assets/img/newRequestIcon.svg'); }
.buyrun-icon.buyrun-icon-supplierProfile { background-image: url('../public/assets/img/supplierProfileIcon.svg'); }
.buyrun-icon.buyrun-icon-changePassword { background-image: url('../public/assets/img/changePasswordIcon.svg'); }
.buyrun-icon.buyrun-icon-shoppingCentre { background-image: url('../public/assets/img/shoppingCentreIcon.svg'); }
.buyrun-icon.buyrun-icon-addRemoveProducts { background-image: url('../public/assets/img/addRemoveProductsIcon.svg'); }
.buyrun-icon.buyrun-icon-salesCharts { background-image: url('../public/assets/img/salesChartsIcon.svg'); }
.buyrun-icon.buyrun-icon-paymentMethods { background-image: url('../public/assets/img/paymentMethodsIcon.svg'); }
.buyrun-icon.buyrun-icon-help { background-image: url('../public/assets/img/helpIcon.svg'); }

.buyrun-icon.buyrun-icon-addFile { background-image: url('../public/assets/img/addFileIcon.svg'); }
.buyrun-icon.buyrun-icon-okFile { background-image: url('../public/assets/img/okFile.svg'); }
.buyrun-icon.buyrun-icon-fileUploaded { background-image: url('../public/assets/img/fileUploadedIcon.svg'); }
.buyrun-icon.buyrun-icon-againFile { background-image: url('../public/assets/img/againFile.svg'); }
.buyrun-icon.buyrun-icon-approvedSupplier { background-image: url('../public/assets/img/approvedSupplierIcon.svg'); }
.buyrun-icon.buyrun-icon-pendingSupplier { background-image: url('../public/assets/img/pendingSupplierIcon.svg'); }
.buyrun-icon.buyrun-icon-check { background-image: url('../public/assets/img/approvedSupplierIcon.svg'); }

.buyrun-icon.buyrun-icon-productDescription { background-image: url('../public/assets/img/product-description.svg'); }
.buyrun-icon.buyrun-icon-productInfo { background-image: url('../public/assets/img/product-info.svg'); }
.buyrun-icon.buyrun-icon-productFeedback { background-image: url('../public/assets/img/product-feedback.svg'); }
.buyrun-icon.buyrun-icon-productSupport { background-image: url('../public/assets/img/product-support.svg'); }

/* Diğer ek stiller ve tanımlamalar burada yer alabilir. */

body {
  line-height: 1.4em;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table tr td {
  padding: 5px 10px;
}

.table.table-striped tr:nth-child(odd){
  background-color: var(--buyrunTableOddBlue);
}

/* Header */
.header-top .header-topLink {
  font-size: 14px;
  text-decoration: none;
  color: initial;
}

.header-top .header-topLink:hover,
.header-top .header-topLink.active {
  color: var(--buyrunOrange);
}

.header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-logoImg {
  margin-bottom: 10px;
}

.header-logoText {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.header-logoText .header-logoText-orange{
 color: var(--buyrunOrange); 
}

.header-logoText .header-logoText-blue{
  color: var(--buyrunBlue); 
}

.header-searchInput {
  background-image: url('../public/assets/img/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  background-size: 24px;
  padding-right: 50px;
}

.header-userItem {
  min-width: 110px;
  max-width: fit-content;
  font-size: 14px;
}

.header-userItem .header-userItem-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 6px;
  background-color: #eee;
  align-content: center;
  justify-content: center;
  margin-bottom: 4px;
}

.header-userItem:hover {
  color: var(--buyrunBlue);
}

.header-userItem:hover .header-userItem-img {
  background-color: var(--buyrunBlue);
  color: #fff;
}

.header-userItem-cart .header-userItem-img {
  position: relative;
}

.header-userItem-cartCount {
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: var(--buyrunOrange);
  border-radius: 50%;
  font-style: normal;
  text-align: center;
  font-size: 12px;
  width: 21px;
  height: 21px;
  line-height: 18px;
  color: #fff;
  border: 2px solid #fff;
}
.product-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5em;
}

.product-about-title {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.5em;
}

.product-pricing {
}

.product-price-value {
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
}

.product-price-value b {
  font-weight: inherit;
  color: var(--buyrunBlue);
  padding-right: 5px;
}

.product-invoice {
  background-color: #dbedfb;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.product-invoice-title {
  line-height: 100%;
}

.product-invoice-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 100%;
}

.product-invoice-text .buyrun-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.product-invoice-link {
  margin-left: 10px;
  text-decoration: none;
}

.product-action {
  
}

.product-info {
}
.product-info b {
  font-weight: 500;
}

/* slide */
.buyrun-slider .slick-slide div div { 
  text-align: center;
}

.buyrun-slider .slick-slide div div img { 
  display: inline-block;
}

.buyrun-slider .slick-dots {
  position: relative;
}

.buyrun-slider .slick-prev,
.buyrun-slider .slick-next {
  top: unset;
  bottom: -20px;
  z-index: 100;
}

.buyrun-slider .slick-prev {
  left: 0;
}

.buyrun-slider .slick-next {
  right: 0;
}

.slick-next:before,
.slick-prev:before{
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  filter: grayscale(1) brightness(100%);
}

.slick-next:before{
  background-image: url("../../../public/assets/img/rightArrow.svg");
}

.slick-prev:before{
  background-image: url("../../../public/assets/img/leftArrow.svg");
}

.slick-prev:hover:before, 
.slick-prev:focus:before,
.slick-next:hover:before, 
.slick-next:focus:before {
  filter: drop-shadow(0 0 5px #aaa);
}

.buyrun-slider .slick-dots li {
  display: inline-flex;
  width: 54px;
  height: 54px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ddd;
}
.buyrun-slider .slick-dots li.slick-active {
  border-color: var(--buyrunBlue);
  box-shadow: 0 0 5px 0 var(--buyrunBlue);
}

.slick-list {
  height: 520px;
}

/* product tabs */
.product-tabs-nav {
  flex-wrap: nowrap!important;
}

.product-tabs-nav .nav-item {
  display: flex;
  width: 100%;
}

.product-tabs-nav .nav-item .nav-link {
  position: relative;
  width: 100%;
  color: #fff;
  background-color: var(--buyrunBlue);
  border-radius: 0;
}

.product-tabs-nav .nav-item:not(:last-child) .nav-link:after {
  content: '';
  position: absolute;
  right: -5px;
  top: 0;
  width: 10px;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0,0,0,0.4), transparent);
  transform: skewX(25deg);
}

.product-tabs-nav .nav-item .nav-link:after {
  z-index: 100!important;
}

/* .product-tabs-nav .nav-item:hover {
  position: relative;
  z-index: 90!important;
}
 */

.product-tabs-nav .nav-item:hover .nav-link {
  color: #000;
}

/* .product-tabs-nav .nav-item:hover:before {
  content: '';
  position: absolute;
  left: -5px;
  top: 0px;
  width: 100%;
  height: calc(100% + 1px);
  background-color: var(--buyrunOrange);
  z-index: 1;
  transform: skewX(25deg);
}
*/

.product-tabs-nav .nav-item:last-child .nav-link {
  border-top-right-radius: 14px;
}

.product-tabs-nav .nav-item:first-child .nav-link {
  border-top-left-radius: 14px;
}

.product-tabs-nav .nav-item.show .nav-link, 
.product-tabs-nav .nav-link.active {
  height: 60px!important;
  margin-top: -21px!important;
}

.product-tabs-nav .nav-link:not(.active) {
  border: 0!important;
}

.product-tabs-nav .nav-link.active {
  z-index: 101;
  border-top-left-radius: 14px;
  border:0;
  background-color: transparent;
  background-image: linear-gradient(65deg, #fff 82%, transparent 82%)!important;
  border-top-right-radius: 55px;
  filter: drop-shadow(5px 0 5px rgba(0,0,0,0.2));
  border: 1px solid #f00;
  border-bottom: 0;
  color: var(--buyrunBlue)!important;
}
.product-tabs-nav .nav-link.active:after {
  width: 40px!important;
  height: calc(100% + 1px)!important;
  background-color: transparent!important;
  background-image: linear-gradient(to right, transparent 0%, #fff 0%)!important;
  box-shadow: none;
  border-top-right-radius: 10px;
  border-top: inherit;
  border-right: inherit;
  margin-top: -1px;
}

.product-tabs-nav .nav-item:not(:first-child) .nav-link.active{
  margin-left: -15px!important;
  width: calc(100% + 15px);
}

.product-tabs-nav .nav-item {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product-tabs-nav {
  margin-top: 100px;
}

.product-tabs .tab-content {
  border: 1px solid var(--bs-border-color);
  border-top: 0;
  padding: 32px;
  z-index: 102;
  position: relative;
  background: #fff;
  border-radius: 0 0 16px 16px;
}

.product-tabs .tab-pane h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 16px;
}

.product-tabs .tab-pane h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 16px;
}

.product-tabs .tab-pane p {
  font-size: 16px;
  line-height: 1.5em;
}